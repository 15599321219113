<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        {{ getTranslateFromItem(main.translation, 'title') }}
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
      <div class="page-section-reg-wrapper">
        <a @click.prevent="scrollToRegSection">
          <BaseButton filled>Зарегистрироваться</BaseButton>
        </a>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">
            <p>{{ getTranslateFromItem(e.translation, 'title') }}</p>
            <p>{{ getTranslateFromItem(e.translation, 'time') }}</p>
          </div>
          <div class="programme-item-content">
            <div class="programme-item-content-desc">{{ getTranslateFromItem(e.translation, 'desc') }}</div>
            <br/>
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description" v-html="getTranslateFromItem(e.translation, 'description')"></div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">Создатели и эксперты курса</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div id="regSection" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center" style="color: #BC1A1A;">Оставить заявку на приобретение места на онлайн-курс</div>
        <div class="hubspot">
          <div class="hubspot-desc">
            <div class="hubspot-desc-text"></div>
            <div class="hubspot-desc-title">Онлайн курс</div>
            <div class="hubspot-desc-description">
              Основы тотального управления доходами в гостиничном бизнесе. Ценовая симуляция — учимся моделировать эффективные стратегии
            </div>
            <div class="hubspot-desc-text">Стоимость курса <b>19 900</b> рублей</div>
          </div>
          <div class="hubspot-form">
            <div id="hubspotForm" ref="hubspot-form1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import webinarImage from '@/assets/images/webinar/webinar.jpg';
import seoImage from '@/assets/images/webinar/feb22.png';
import speakerNikolaosImage from '@/assets/images/speakers/nikolaos.png';
import speakerAnatoliyImage from '@/assets/images/speakers/anatoliy2.jpg';
import speakerKovriginImage from '@/assets/images/speakers/kovrigin.jpg';
import frontdeskImage from '@/assets/images/partners/frontdesk.png';
import wrkhImage from '@/assets/images/partners/wrkh.jpg';
import wtImage from '@/assets/images/partners/wt.png';
import horecaImage from '@/assets/images/partners/horeca.png';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
  },
  methods: {
    scrollToRegSection() {
      const section = document.getElementById("regSection");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "464f60df-28c9-4156-991a-4a9224c0c514",
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: 'Онлайн курс',
          date: 'Основы тотального управления доходами в гостиничном бизнесе. \n Ценовая симуляция — учимся моделировать эффективные стратегии.'
        },
        en: {
          title: 'Онлайн курс',
          date: 'Основы тотального управления доходами в гостиничном бизнесе. \n Ценовая симуляция — учимся моделировать эффективные стратегии.'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'О чем наш курс',
          before: '<p>Управление доходами и стратегии ценообразования являются ключевыми элементами успешного бизнеса. С помощью нашего курса вы приобретете глубокие практические знания и повысите свой профессиональный уровень. Онлайн-курс предназначен для продвинутых менеджеров по доходам, которые хотят стать настоящими экспертами в этой области. С нашим курсом вы получите понимание принципов оптимизации общего дохода, прогнозирования, стратегий ценообразования, практического моделирования цен и многого другого.</p>' +
          '<div class="page-section-title-center">Что будет на курсе</div>' +
          '<ul><li>аспекты тотального управления доходами - от тактического до стратегического, от частного до глобального</li>' +
          '<li>основы стратегического и тотального планирования</li>' +
          '<li>практические методы прогнозирования доходов, которые применяются в разных сферах отельного бизнеса</li>' +
          '<li>лучшие мировые практики применения принципов тотального управления</li></ul>' +
          '<p><b>Будем учиться пользоваться самой уникальной и интересной фишкой курса — программой hotellab:Pricing Simulator.</b>. Эта обучающая ценовая симуляция позволяет проверять ваши знания в области динамического ценообразования, помогает оценить эффективность построенных стратегий на основе реальных данных о заполненности номеров, о конкурентах, о рынке и других факторах.<p>' +
          '<b>Hotellab:Pricing Simulator</b> — идеальный способ научиться принимать правильные решения в условиях высококонкурентного гостиничного бизнеса.</p>' +
          '<ul><li>Не упустите возможность стать экспертом в управлении доходами</li>' +
          '<li>Набейте руку в построении ценовых стратегий</li>' +
          '<li>Сделайте шаг навстречу практическим знаниям, которые помогут повысить вашу карьеру и уверенно войти в мир управления доходами</li>' +
          '<li>Воспользуйтесь преимуществами интерактивных учебных материалов, чтобы быть впереди конкурентов</li></ul>' +
          '<p>Присоединяйтесь к нашему онлайн-курсу, не ждите, так как количество мест ограничено.</p>' +
          '<div class="page-section-title-center">Дополнительно вы получите:</div>' +
          '<ul><li>Именной сертификат о прохождении курса</li>' +
          '<li>Рекомендации от создателей курса в подтверждение повышения вашей профессиональной квалификации</li>' +
          '<li>Чат с коллегами, который можно использовать в дальнейшем для эффективного нетворкинга</li>' +
          '<li>Приятные бонусы от hotellab для всех участников курса</li></ul>',
          after: ''
        },
        en: {
          title: 'О чем наш курс',
          before: '<p>Управление доходами и стратегии ценообразования являются ключевыми элементами успешного бизнеса. С помощью нашего курса вы приобретете глубокие практические знания и повысите свой профессиональный уровень. Онлайн-курс предназначен для продвинутых менеджеров по доходам, которые хотят стать настоящими экспертами в этой области. С нашим курсом вы получите понимание принципов оптимизации общего дохода, прогнозирования, стратегий ценообразования, практического моделирования цен и многого другого.</p>' +
          '<div class="page-section-title-center">Что будет на курсе</div>' +
          '<ul><li>аспекты тотального управления доходами - от тактического до стратегического, от частного до глобального</li>' +
          '<li>основы стратегического и тотального планирования</li>' +
          '<li>практические методы прогнозирования доходов, которые применяются в разных сферах отельного бизнеса</li>' +
          '<li>лучшие мировые практики применения принципов тотального управления</li></ul>' +
          '<p><b>Будем учиться пользоваться самой уникальной и интересной фишкой курса — программой hotellab:Pricing Simulator.</b>. Эта обучающая ценовая симуляция позволяет проверять ваши знания в области динамического ценообразования, помогает оценить эффективность построенных стратегий на основе реальных данных о заполненности номеров, о конкурентах, о рынке и других факторах.<p>' +
          '<b>Hotellab:Pricing Simulator</b> — идеальный способ научиться принимать правильные решения в условиях высококонкурентного гостиничного бизнеса.</p>' +
          '<ul><li>Не упустите возможность стать экспертом в управлении доходами</li>' +
          '<li>Набейте руку в построении ценовых стратегий</li>' +
          '<li>Сделайте шаг навстречу практическим знаниям, которые помогут повысить вашу карьеру и уверенно войти в мир управления доходами</li>' +
          '<li>Воспользуйтесь преимуществами интерактивных учебных материалов, чтобы быть впереди конкурентов</li></ul>' +
          '<p>Присоединяйтесь к нашему онлайн-курсу, не ждите, так как количество мест ограничено.</p>' +
          '<div class="page-section-title-center">Дополнительно вы получите:</div>' +
          '<ul><li>Именной сертификат о прохождении курса</li>' +
          '<li>Рекомендации от создателей курса в подтверждение повышения вашей профессиональной квалификации</li>' +
          '<li>Чат с коллегами, который можно использовать в дальнейшем для эффективного нетворкинга</li>' +
          '<li>Приятные бонусы от hotellab для всех участников курса</li></ul>',
          after: ''
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 0,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'От тактического управления доходами к стратегическому и тотальному',
                  speaker: '',
                  modules: 'Кейс: Какая стратегия лучше? Как стратегии в управлении номерным фондом влияют на прибыль и доходы других подразделений'
                },
                en: {
                  title: 'От тактического управления доходами к стратегическому и тотальному',
                  speaker: '',
                  modules: 'Кейс: Какая стратегия лучше? Как стратегии в управлении номерным фондом влияют на прибыль и доходы других подразделений'
                },
              },
            },
            {
              general: {
                id: 1,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Принципы стратегического и тотального планирования',
                  speaker: '',
                  modules: 'Кейс: Решаем проблему с повышением доходов конференц-залов \n' +
                  'Кейс: Решаем проблему с повышением доходов ресторана \n'
                },
                en: {
                  title: 'Принципы стратегического и тотального планирования',
                  speaker: '',
                  modules: 'Кейс: Решаем проблему с повышением доходов конференц-залов \n' +
                  'Кейс: Решаем проблему с повышением доходов ресторана \n'
                },
              },
            },
            {
              general: {
                id: 2,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Основы стратегического и тотального прогнозирования',
                  speaker: '',
                  modules: 'Прогнозирование доходов номерного фонда \n' +
                  '• Прогнозирование количества гостей \n' +
                  '• Прогнозирование доходов F&B \n' +
                  '• Прогнозирование доходов других подразделений \n' +
                  '• Как точность прогнозирования связана с прибылью каждого подразделения?'
                },
                en: {
                  title: 'Основы стратегического и тотального прогнозирования',
                  speaker: '',
                  modules: '• Прогнозирование доходов номерного фонда \n' +
                  '• Прогнозирование количества гостей \n' +
                  '• Прогнозирование доходов F&B \n' +
                  '• Прогнозирование доходов других подразделений \n' +
                  '• Как точность прогнозирования связана с прибылью каждого подразделения?'
                },
              },
            },
            {
              general: {
                id: 3,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Оптимизация и ключевые операционные показатели в тотальном управлении доходами',
                  speaker: '',
                  modules: '• Бары, рестораны \n' +
                  '• Конференц-залы \n' +
                  '• Все подразделения'
                },
                en: {
                  title: 'Оптимизация и ключевые операционные показатели в тотальном управлении доходами',
                  speaker: '',
                  modules: '• Бары, рестораны \n' +
                  '• Конференц-залы \n' +
                  '• Все подразделения'
                },
              },
            },
            {
              general: {
                id: 4,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Лучшие мировые практики применения принципов тотального управления',
                  speaker: '',
                  modules: ''
                },
                en: {
                  title: 'Лучшие мировые практики применения принципов тотального управления',
                  speaker: '',
                  modules: ''
                },
              },
            },
          ],
          translation: {
            ru: {
              title: 'День первый',
              time: "10:00 - 15:00 мск",
              desc: 'Основы тотального управления доходами:'
            },
            en: {
              title: 'День первый',
              time: "10:00 - 15:00 мск",
              desc: 'Основы тотального управления доходами:'
            },
          },
        },
        {
          general: {
            id: 1,
          },
          programme: [
            {
              general: {
                id: 0,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Настройка проекта обучающего отеля',
                  speaker: '',
                  modules: '• Настройка симуляции —  4-звездочного отеля в Москве с тремя категориями номеров, центральным расположением и коротким окном бронирования \n' +
                  '• Выбор конкурентов и их сопоставление \n' +
                  '• Настройка ценовых стратегий и их управление \n' +
                  '• Изучение различий работы с уровнями цен (BAR) и открытым ценообразованием (open-pricing) \n' +
                  '• Работа с групповыми запросами \n' +
                  '• Анализ, на основе бенчмаркинга, показателей аналитики спроса и результатов'
                },
                en: {
                  title: 'Настройка проекта обучающего отеля',
                  speaker: '',
                  modules: '• Настройка симуляции —  4-звездочного отеля в Москве с тремя категориями номеров, центральным расположением и коротким окном бронирования \n' +
                  '• Выбор конкурентов и их сопоставление \n' +
                  '• Настройка ценовых стратегий и их управление \n' +
                  '• Изучение различий работы с уровнями цен (BAR) и открытым ценообразованием (open-pricing) \n' +
                  '• Работа с групповыми запросами \n' +
                  '• Анализ, на основе бенчмаркинга, показателей аналитики спроса и результатов'
                },
              },
            },
            {
              general: {
                id: 1,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Изучение ценовых решений в условиях смоделированной конкурентной среды',
                  speaker: '',
                  modules: '• Анализ данных внутреннего и внешнего спроса (темп бронирований, динамика конкурентов, индекс спроса и т.д.) \n' +
                  '• Подсказки для самообучения принятию ценовых решений \n' +
                  '• Алгоритм hotellab будет подсказывать, как увеличить потенциал дохода \n' +
                  '• Алгоритм будет отображать точность принятия решений, на основе смоделированной среды'
                },
                en: {
                  title: 'Изучение ценовых решений в условиях смоделированной конкурентной среды',
                  speaker: '',
                  modules: '• Анализ данных внутреннего и внешнего спроса (темп бронирований, динамика конкурентов, индекс спроса и т.д.) \n' +
                  '• Подсказки для самообучения принятию ценовых решений \n' +
                  '• Алгоритм hotellab будет подсказывать, как увеличить потенциал дохода \n' +
                  '• Алгоритм будет отображать точность принятия решений, на основе смоделированной среды'
                },
              },
            },
          ],
          translation: {
            ru: {
              title: 'День второй',
              time: "10:00 - 15:00 мск",
              desc: 'Практика и моделирование:'
            },
            en: {
              title: 'День второй',
              time: "10:00 - 15:00 мск",
              desc: 'Практика и моделирование:'
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Программа курса:',
          description: '',
        },
        en: {
          title: 'Программа курса:',
          description: '',
        },
      },
    },
    partners: [
      {
        "id": 0,
        "generic": {
          "photo": "https://hotellab.io/media/hotelier.png",
          "url": "https://hotelier.pro",
        },
      },
      {
        "id": 1,
        "generic": {
          "photo": wrkhImage,
          "url": "https://t.me/wrkhotel",
        },
      },
      {
        "id": 2,
        "generic": {
          "photo": wtImage,
          "url": "https://welcometimes.ru/",
        },
      },
      {
        "id": 3,
        "generic": {
          "photo": horecaImage,
          "url": "https://horeca.estate/",
        },
      },
      {
        "id": 4,
        "generic": {
          "photo": frontdeskImage,
          "url": "https://www.frontdesk.ru/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho.webp',
        },
        translation: {
          ru: {
            title: 'Кому будет полезен курс:',
            description: '<ul><li>Revenue менеджерам крупных отелей</li>' +
            '<li>Директорам по продажам, в ответственности которых лежит Revenue Management</li>' +
            '<li>Генеральным менеджерам с опытом Revenue Management</li>',
          },
          en: {
            title: 'Кому будет полезен курс:',
            description: '<ul><li>Revenue менеджерам крупных отелей</li>' +
            '<li>Директорам по продажам, в ответственности которых лежит Revenue Management</li>' +
            '<li>Генеральным менеджерам с опытом Revenue Management</li>',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy.jpg',
        },
        translation: {
          ru: {
            title: 'Что получат слушатели курса:',
            description: '<ul><li>огромное количество практической информации по современным стандартам и технологиям Revenue Management</li>' +
            '<li>профессиональные актуальные знания от ведущих специалистов в области revenue- технологий</li>' +
            '<li>конкурентное преимущество как для своего отеля, так и для себя, как revenue- профессионала</li>' +
            '<li>сертификат об успешном прохождении курса, который можно использовать в резюме</li></ul>'
          },
          en: {
            title: 'Что получат слушатели курса:',
            description: '<ul><li>огромное количество практической информации по современным стандартам и технологиям Revenue Management</li>' +
            '<li>профессиональные актуальные знания от ведущих специалистов в области revenue- технологий</li>' +
            '<li>конкурентное преимущество как для своего отеля, так и для себя, как revenue- профессионала</li>' +
            '<li>сертификат об успешном прохождении курса, который можно использовать в резюме</li></ul>'
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerAnatoliyImage,
        },
        translation: {
          ru: {
            name: 'Анатолий Бурдаков',
            description: 'hotellab.io. 12-лет опыта в управлении доходами',
          },
          en: {
            name: 'Анатолий Бурдаков',
            description: 'hotellab.io. 12-лет опыта в управлении доходами',
          },
        },
      },
      {
        general: {
          photo: speakerNikolaosImage,
        },
        translation: {
          ru: {
            name: 'Николаос Тситсулис',
            description: 'Revify. 10-лет опыта в управлении доходами',
          },
          en: {
            name: 'Николаос Тситсулис',
            description: 'Revify. 10-лет опыта в управлении доходами',
          },
        },
      },
      {
        general: {
          photo: speakerKovriginImage,
        },
        translation: {
          ru: {
            name: 'Алексей Ковригин',
            description: 'RevMax. 20-лет опыта в управлении доходами',
          },
          en: {
            name: 'Алексей Ковригин',
            description: 'RevMax. 20-лет опыта в управлении доходами',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.page-section-wrapper.pt-0 {
  padding-top: 0;
}

.page-section-reg-wrapper {
  padding: 30px;
}

.page-section-reg-wrapper span {
  margin-right: 20px;
}

.demo-main-photo-title-date {
  white-space: pre-wrap;
}

.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;

  .page-section-reg-wrapper {
    padding: 30px;
  }

  .page-section-reg-wrapper span {
    margin-right: 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    &-desc {
      font-size: 25px;
      text-transform: uppercase;
    }

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;
    background-color: #5d5ca8;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 700px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        text-align: left;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
.hubspot {
  display: flex;
  flex-direction: row;

  @media (max-width: $breakpoint-tablet-v) {
    display: block;
  }

  &-desc {
    padding-top: 40px;
    padding-right: 8%;
    padding-left: 5%;
    width: 37%;
    text-align: left;

    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
      text-align: left;
      padding: 0;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    &-text {
      padding-bottom: 20px;
      opacity: 1;
      color: #2F3238;
      font-size: 18px;

      @media (max-width: $breakpoint-tablet-v) {
        font-size: 16px;
      }
    }

    &-title {
      padding-bottom: 20px;
      font-weight: 500;
      font-size: 32px;
      color: #5D5CA8;

      @media (max-width: $breakpoint-tablet-v) {
        font-size: 28px;
      }
    }

    &-description {
      padding-bottom: 20px;
      font-weight: 450;
      font-size: 24px;
      color: #5D5CA8;

      @media (max-width: $breakpoint-tablet-v) {
        font-size: 20px;
      }
    }
  }

  &-form {
    width: 60%;

    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
    }
  }
}
</style>
