var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"webinar"}},[_c('div',{staticClass:"demo-main-photo",style:({background: `url('${_vm.webinarImage}') 50% 50%`, backgroundSize: 'cover',})},[_c('div',{staticClass:"demo-main-photo-title"},[_vm._v(" "+_vm._s(_vm.getTranslateFromItem(_vm.main.translation, 'title'))+" "),_c('div',{staticClass:"demo-main-photo-title-date"},[_vm._v(" "+_vm._s(_vm.getTranslateFromItem(_vm.main.translation, 'date'))+" ")])]),_c('div',{staticClass:"page-section-reg-wrapper"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.scrollToRegSection.apply(null, arguments)}}},[_c('BaseButton',{attrs:{"filled":""}},[_vm._v("Зарегистрироваться")])],1)])]),_c('div',{staticClass:"page-section-wrapper"},[_c('div',{staticClass:"page-section-inner"},[_c('div',{staticClass:"page-section-title-center"},[_vm._v(" "+_vm._s(_vm.getTranslateFromItem(_vm.description.translation, 'title'))+" ")]),_c('div',{style:({
          fontSize: '20px',
          lineHeight: '30px',
          padding: '0px',
          whiteSpace: 'pre-wrap',
        })},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.descriptionBefore)}}),_c('ul',_vm._l((_vm.description.list),function(e){return _c('li',[_vm._v(_vm._s(_vm.getTranslateFromItem(e.translate, 'text')))])}),0),_c('div',[_vm._v(_vm._s(_vm.getTranslateFromItem(_vm.description.translation, 'after')))])])])]),_c('div',{staticClass:"page-section-wrapper"},[_c('div',{staticClass:"page-section-inner"},[_c('div',{staticClass:"page-section-title-center"},[_vm._v(_vm._s(_vm.getTranslateFromItem(_vm.program.translation, 'title')))]),_vm._l((_vm.program.days),function(e){return _c('div',{staticClass:"programme-item"},[_c('div',{staticClass:"programme-item-day"},[_c('div',[_vm._v(_vm._s(_vm.getTranslateFromItem(e.translation, 'title')))]),_c('div',[_vm._v(_vm._s(_vm.getTranslateFromItem(e.translation, 'time')))])]),_c('div',{staticClass:"programme-item-content"},[_c('ol',{attrs:{"type":"1"}},_vm._l((e.programme),function(item,i){return _c('li',{staticClass:"programme-item-content-item"},[_c('div',{staticClass:"programme-item-content-item-title"},[_vm._v(_vm._s(_vm.getTranslateFromItem(item.translation, 'title')))]),(item.general.speaker)?_c('div',{staticClass:"programme-item-content-item-speaker"},[_vm._v(_vm._s(_vm.getTranslateFromItem(item.translation, 'speaker')))]):_vm._e(),_c('div',{staticClass:"programme-item-content-item-modules"},[_vm._v(_vm._s(_vm.getTranslateFromItem(item.translation, 'modules')))])])}),0)])])})],2)]),_vm._l((_vm.targets),function(e){return _c('div',{staticClass:"page-section-wrapper marketing-section-analytics-wrapper",class:{
        'marketing-section-analytics-wrapper__reverse': e.general.reverse,
      }},[_c('div',{staticClass:"page-section-inner marketing-section-analytics"},[_c('div',{staticClass:"marketing-section-analytics-image"},[_c('img',{attrs:{"src":e.general.photo,"alt":""}})]),_c('div',{staticClass:"marketing-section-analytics-info"},[_c('div',{staticClass:"marketing-section-analytics-info-title"},[_vm._v(" "+_vm._s(_vm.getTranslateFromItem(e.translation, 'title'))+" ")]),_c('div',{staticClass:"marketing-section-analytics-info-description",domProps:{"innerHTML":_vm._s(_vm.getTranslateFromItem(e.translation, 'description'))}})])])])}),_c('div',{staticClass:"page-section-wrapper"},[_c('div',{staticClass:"page-section-inner"},[_c('div',{staticClass:"page-section-title-center"},[_vm._v(_vm._s(_vm.getTranslate('webinarSpeakersTitle')))]),_c('CarouselWebinar',{attrs:{"speakers":_vm.speakers}})],1)]),_c('div',{staticClass:"page-section-wrapper",attrs:{"id":"home-section-integrations-wrapper"}},[_c('div',{staticClass:"page-section-inner",attrs:{"id":"home-section-integrations"}},[_c('div',{staticClass:"page-section-title-center",attrs:{"id":"home-section-integrations-title"}},[_vm._v(" "+_vm._s(_vm.getTranslate('webinarPartnersTitle'))+" ")]),_c('div',{attrs:{"id":"home-section-integrations-logos"}},_vm._l((_vm.partners),function(e){return _c('a',{staticClass:"home-section-integrations-logos-item",attrs:{"href":e.generic.url,"target":"_blank"}},[_c('img',{attrs:{"src":e.generic.photo,"alt":""}})])}),0)])]),_c('div',{staticClass:"page-section-wrapper",attrs:{"id":"regSection"}},[_c('div',{staticClass:"page-section-inner"},[_c('div',{staticClass:"page-section-title-center"},[_vm._v(_vm._s(_vm.getTranslate('webinarFormTitle')))]),_c('div',{ref:"hubspot-form1",attrs:{"id":"hubspotForm"}})])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }