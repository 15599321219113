<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        {{ getTranslateFromItem(main.translation, 'title') }}
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
      <div class="page-section-reg-wrapper">
        <a @click.prevent="scrollToRegSection">
          <BaseButton filled>Зарегистрироваться</BaseButton>
        </a>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">
            <div>{{ getTranslateFromItem(e.translation, 'title') }}</div>
            <div>{{ getTranslateFromItem(e.translation, 'date') }}</div>
            <div>{{ getTranslateFromItem(e.translation, 'time') }}</div>
          </div>
          <div class="programme-item-content">
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description" v-html="getTranslateFromItem(e.translation, 'description')"></div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarSpeakersTitle') }}</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div id="regSection" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarFormTitle') }}</div>
        <div id="hubspotForm" ref="hubspot-form1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import webinarImage from '@/assets/images/webinar/webinar.jpg';
import seoImage from '@/assets/images/webinar/feb22.png';
import speakerMagomedovaImage from '@/assets/images/speakers/magomedova.jpg';
import speakerAnatoliyImage from '@/assets/images/speakers/anatoliy2.jpg';
import speakerKovriginImage from '@/assets/images/speakers/kovrigin.jpg';
import speakerKireevImage from '@/assets/images/speakers/kireev.jpg';
import speakerBezbImage from '@/assets/images/speakers/bezborodova.jpeg';
import speakerSharovImage from '@/assets/images/speakers/sharov.png';
import speakerIpatovaImage from '@/assets/images/speakers/ipatova.jpg';
import speakerBihalovaImage from '@/assets/images/speakers/bihalova.png';
import speakerCvangImage from '@/assets/images/speakers/cvang.png';
import speakerZlatcusImage from '@/assets/images/speakers/zlatcus.jpg';
import travellineImage from '@/assets/images/partners/travelline.png';
import hotbotImage from '@/assets/images/partners/HotbotBlack.png';
import hrsImage from '@/assets/images/partners/hrs.jpg';
import ostrovokImage from '@/assets/images/partners/ostrovok.png';
import hotelAdvImage from '@/assets/images/partners/hotel-advisor.png';
import hotellabImage from '@/assets/images/partners/hotellab.png';
import aviasalesImage from '@/assets/images/partners/aviasales.png';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
  },
  methods: {
    scrollToRegSection() {
      const section = document.getElementById("regSection");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "d16fa391-0114-4398-b898-111ae9a01516",
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: 'Web Revenue Workshop',
          date: 'Для менеджмента отелей с номерным фондом более 20 номеров \n Онлайн | 10 — 11 Апреля | 14:00 — 16:00 мск'
        },
        en: {
          title: 'Web Revenue Workshop',
          date: 'Для менеджмента отелей с номерным фондом более 20 номеров \n Онлайн | 10 — 11 Апреля | 14:00 — 16:00 мск'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'О чём наш воркшоп',
          before: '<p><b>Тема:</b> Управление доходами и ведущие тренды в развитии гостиничного бизнеса. Аналитика. Автоматизация. Искусственный интеллект. Их влияние, возможности и будущее в управлении доходами отелей</p>' +
          '<p><b>Организаторы:</b> компании hotellab, TravelLine, Hotbot.ai, HRS, Ostrovok, при участии Aviastats</p>' +
          '<p>Компания hotellab.io, совместно с партнёрами, проводит Web Revenue Workshop, посвященный развитию аналитики, автоматизации и искусственного интеллекта в гостиничном бизнесе.</p>' +
          '<p>В индустрии гостеприимства управление доходами базируется, с одной стороны, на исторических данных, с другой — на данных о прогнозируемом будущем. Сегодня развитие технологий позволяет отелям быстрее освободиться от рутинных, повторяющихся задач. Современные отельеры должны смотреть в будущее и создавать позиции завтрашнего дня.</p>' +
          '<p><b>На воркшопе обсудим:</b></p>' +
          '<ul><li>Как аналитика и автоматизация уже сейчас кардинально меняют процессы в управлении доходами</li>' +
          '<li>Какие возможности новые технологии открывают перед отельным бизнесом в будущем</li>' +
          '<li>Как искусственный интеллект позволяет отельерам перестраивать тактический подход к управлению доходами и ценообразованию на более целостный и стратегический</li></ul>' +
          '<p><b>Основные подробности:</b></p>' +
          '<ul><li>Компания hotellab и Aviastats, сервис аналитики “Авиасейлс”, анонсируют уникальный проект, помогающий улучшить прогнозирование в hotellab:RMS на основе данных о перелетах. Познакомят вас с аналитическими трендами в авиаперелетах и бронированиях отелей</li>' +
          '<li>Компания Ostrovok проанализирует ключевые тренды бронирований на примере своего ОТА. Объяснит, как Ostrovok помогает увеличивать спрос в отеле</li>' +
          '<li>Компания Hotel Advisors представит анализ текущей ситуации на рынке по результатам первого квартала 2024 года</li>' +
          '<li>Компании TravelLine, HRS и Hotbot сделают подробные обзоры собственных цифровых решений в свете последних трендов автоматизации</li>' +
          '<li>Компании - организаторы расскажут как автоматизация и аналитика помогают отелям организовать эффективную  работу с решениями искусственного интеллекта</li></ul>' +
          '<p>Наш воркшоп будет полезен владельцам и менеджменту отелей с номерным фондом от 20 номеров и более.</p>' +
          '<p>Объем знаний и экспертной информации, который вы получите на воркшопе, позволит вам не только не остаться в стороне от ведущих направлений развития индустрии, но и быть впереди значительного числа конкурентов.</p>' +
          '<p>Участие для отельеров бесплатное.</p>',
          after: ''
        },
        en: {
          title: 'О чём наш воркшоп',
          before: '<p><b>Тема:</b> Управление доходами и ведущие тренды в развитии гостиничного бизнеса. Аналитика. Автоматизация. Искусственный интеллект. Их влияние, возможности и будущее в управлении доходами отелей</p>' +
          '<p><b>Организаторы:</b> компании hotellab, TravelLine, Hotbot.ai, HRS, Ostrovok, при участии Aviastats</p>' +
          '<p>Компания hotellab.io, совместно с партнёрами, проводит Web Revenue Workshop, посвященный развитию аналитики, автоматизации и искусственного интеллекта в гостиничном бизнесе.</p>' +
          '<p>В индустрии гостеприимства управление доходами базируется, с одной стороны, на исторических данных, с другой — на данных о прогнозируемом будущем. Сегодня развитие технологий позволяет отелям быстрее освободиться от рутинных, повторяющихся задач. Современные отельеры должны смотреть в будущее и создавать позиции завтрашнего дня.</p>' +
          '<p><b>На воркшопе обсудим:</b></p>' +
          '<ul><li>Как аналитика и автоматизация уже сейчас кардинально меняют процессы в управлении доходами</li>' +
          '<li>Какие возможности новые технологии открывают перед отельным бизнесом в будущем</li>' +
          '<li>Как искусственный интеллект позволяет отельерам перестраивать тактический подход к управлению доходами и ценообразованию на более целостный и стратегический</li></ul>' +
          '<p><b>Основные подробности:</b></p>' +
          '<ul><li>Компания hotellab и Aviastats, сервис аналитики “Авиасейлс”, анонсируют уникальный проект, помогающий улучшить прогнозирование в hotellab:RMS на основе данных о перелетах. Познакомят вас с аналитическими трендами в авиаперелетах и бронированиях отелей</li>' +
          '<li>Компания Ostrovok проанализирует ключевые тренды бронирований на примере своего ОТА. Объяснит, как Ostrovok помогает увеличивать спрос в отеле</li>' +
          '<li>Компания Hotel Advisors представит анализ текущей ситуации на рынке по результатам первого квартала 2024 года</li>' +
          '<li>Компании TravelLine, HRS и Hotbot сделают подробные обзоры собственных цифровых решений в свете последних трендов автоматизации</li>' +
          '<li>Компании - организаторы расскажут как автоматизация и аналитика помогают отелям организовать эффективную  работу с решениями искусственного интеллекта</li></ul>' +
          '<p>Наш воркшоп будет полезен владельцам и менеджменту отелей с номерным фондом от 20 номеров и более.</p>' +
          '<p>Объем знаний и экспертной информации, который вы получите на воркшопе, позволит вам не только не остаться в стороне от ведущих направлений развития индустрии, но и быть впереди значительного числа конкурентов.</p>' +
          '<p>Участие для отельеров бесплатное.</p>',
          after: ''
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 0,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Аналитика. Бенчмаркинг без границ: как работать с рыночными данными сегодня',
                  speaker: 'Ольга Златкус, менеджер по развитию бизнеса, Hotel Advisors',
                  modules: ''
                },
                en: {
                  title: 'Аналитика. Бенчмаркинг без границ: как работать с рыночными данными сегодня',
                  speaker: 'Ольга Златкус, менеджер по развитию бизнеса, Hotel Advisors',
                  modules: ''
                },
              },
            },
            {
              general: {
                id: 1,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Анонс партнерства hotellab и Aviasales. Новый взгляд на управление доходами с помощью Аviastats, сервиса аналитики от компании “Авиасейлс”',
                  speaker: 'Анатолий Бурдаков, основатель hotellab, \nМария Ипатова, Business development manager, “Авиасейлс”',
                  modules: ''
                },
                en: {
                  title: 'Анонс партнерства hotellab и Aviasales. Новый взгляд на управление доходами с помощью Аviastats, сервиса аналитики от компании “Авиасейлс”',
                  speaker: 'Анатолий Бурдаков, основатель hotellab, \nМария Ипатова, Business development manager, “Авиасейлс”',
                  modules: ''
                },
              },
            },
            {
              general: {
                id: 2,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Аналитика hotellab & Aviastats. Статистика перелетов и ценообразование в отеле',
                  speaker: 'Анатолий Бурдаков, основатель hotellab',
                  modules: ''
                },
                en: {
                  title: 'Аналитика hotellab & Aviastats. Статистика перелетов и ценообразование в отеле',
                  speaker: 'Анатолий Бурдаков, основатель hotellab',
                  modules: ''
                },
              },
            },
            {
              general: {
                id: 3,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Ключевые тренды бронирований отелей',
                  speaker: 'Ксения Безбородова, руководитель команды по развитию бизнеса в СЗФО и Республике Беларусь, Оstrovok',
                  modules: ''
                },
                en: {
                  title: 'Ключевые тренды бронирований отелей',
                  speaker: 'Ксения Безбородова, руководитель команды по развитию бизнеса в СЗФО и Республике Беларусь, Оstrovok',
                  modules: ''
                },
              },
            },
          ],
          translation: {
            ru: {
              title: 'День первый',
              date: '10 апреля',
              time: '14:00 — 16:00 МСК'
            },
            en: {
              title: 'День первый',
              date: '10 апреля',
              time: '14:00 — 16:00 МСК'
            },
          },
        },
        {
          general: {
            id: 1,
          },
          programme: [
            {
              general: {
                id: 0,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Как трансформируется управление доходами в эпоху искусственного интеллекта',
                  speaker: 'Анатолий Бурдаков, основатель hotellab\nАлексей Ковригин, директор по продукту hotellab:RMS',
                  modules: ''
                },
                en: {
                  title: 'Как трансформируется управление доходами в эпоху искусственного интеллекта',
                  speaker: 'Анатолий Бурдаков, основатель hotellab\nАлексей Ковригин, директор по продукту hotellab:RMS',
                  modules: ''
                },
              },
            },
            {
              general: {
                id: 1,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Тренды автоматизации от HRS',
                  speaker: 'Айна Магомедова, региональный менеджер HRS',
                  modules: ''
                },
                en: {
                  title: 'Тренды автоматизации от HRS',
                  speaker: 'Айна Магомедова, региональный менеджер HRS',
                  modules: ''
                },
              },
            },
            {
              general: {
                id: 2,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Цифры, которые помогают выполнять планы',
                  speaker: 'Федор Киреев, директор по продукту, Hotbot.ai',
                  modules: ''
                },
                en: {
                  title: 'Цифры, которые помогают выполнять планы',
                  speaker: 'Федор Киреев, директор по продукту, Hotbot.ai',
                  modules: ''
                },
              },
            },
            {
              general: {
                id: 3,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Открытая дискуссия на тему работы с Hurdle Rates в управлении доходами',
                  speaker: 'Александр Шаров, Директор департамента по работе с ключевыми клиентами TravelLine\n' +
                  'Елена Быхалова, эксперт по управлению доходами Лолиту Цванг, региональный директор по управлению доходами, Cosmos Hotel Group\n' + 
                  'Представители компаний Ostrovok, hotellab и отельеры - практики',
                  modules: ''
                },
                en: {
                  title: 'Открытая дискуссия на тему работы с Hurdle Rates в управлении доходами',
                  speaker: 'Александр Шаров, Директор департамента по работе с ключевыми клиентами TravelLine\n' +
                  'Елена Быхалова, эксперт по управлению доходами Лолиту Цванг, региональный директор по управлению доходами, Cosmos Hotel Group\n' + 
                  'Представители компаний Ostrovok, hotellab и отельеры - практики',
                  modules: ''
                },
              },
            },
          ],
          translation: {
            ru: {
              title: 'День второй',
              date: '11 апреля',
              time: '14:00 — 16:00 МСК'
            },
            en: {
              title: 'День второй',
              date: '11 апреля',
              time: '14:00 — 16:00 МСК'
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Программа воркшопа:',
          description: '',
        },
        en: {
          title: 'Программа воркшопа:',
          description: '',
        },
      },
    },
    partners: [
      {
        "id": 0,
        "generic": {
          "photo": travellineImage,
          "url": "https://www.travelline.ru/",
        },
      },
      {
        "id": 1,
        "generic": {
          "photo": ostrovokImage,
          "url": "https://ostrovok.ru/",
        },
      },
      {
        "id": 2,
        "generic": {
          "photo": hotbotImage,
          "url": "https://hotbot.ai/",
        },
      },
      {
        "id": 3,
        "generic": {
          "photo": hrsImage,
          "url": "https://www.hrsinternational.com/russia/ru/",
        },
      },
      {
        "id": 4,
        "generic": {
          "photo": hotellabImage,
          "url": "https://hotellab.io/ru/",
        },
      },
      {
        "id": 5,
        "generic": {
          "photo": aviasalesImage,
          "url": "https://www.aviasales.ru/",
        },
      },
      {
        "id": 6,
        "generic": {
          "photo": hotelAdvImage,
          "url": "https://hoteladvisors.ru/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho.webp',
        },
        translation: {
          ru: {
            title: 'Для кого?',
            description: '<ul><li>Для руководства и менеджмента отелей номерным фондом более 20 номеров<//li>' +
            '<li>Для всех отельеров, стремящихся всегда быть на передовых позициях в гостиничной индустрии</li></ul>',
          },
          en: {
            title: 'For Who?',
            description: '<ul><li>Для руководства и менеджмента отелей номерным фондом более 20 номеров<//li>' +
            '<li>Для всех отельеров, стремящихся всегда быть на передовых позициях в гостиничной индустрии</li></ul>',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy.jpg',
        },
        translation: {
          ru: {
            title: 'Что даст?',
            description: '<ul><li>Подробные экспертные знания о тенденциях будущего развития гостиничного бизнеса</li>' +
            '<li>Возможности практического применения в собственном отеле самых последних технологий, увеличивающих доход и узнаваемость в интернете</li>' +
            '<li>Понимание принципов использования искусственного интеллекта в сфере управления доходами и маркетинга</li></ul>'
          },
          en: {
            title: 'What Can You Get?',
            description: '<ul><li>Подробные экспертные знания о тенденциях будущего развития гостиничного бизнеса</li>' +
            '<li>Возможности практического применения в собственном отеле самых последних технологий, увеличивающих доход и узнаваемость в интернете</li>' +
            '<li>Понимание принципов использования искусственного интеллекта в сфере управления доходами и маркетинга</li></ul>'
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerAnatoliyImage,
        },
        translation: {
          ru: {
            name: 'Анатолий Бурдаков',
            description: 'Основатель hotellab.io',
          },
          en: {
            name: 'Anatoly Burdakov',
            description: 'Founder of hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerMagomedovaImage,
        },
        translation: {
          ru: {
            name: 'Айна Магомедова',
            description: 'Региональный менеджер HRS',
          },
          en: {
            name: 'Айна Магомедова',
            description: 'Региональный менеджер HRS',
          },
        },
      },
      {
        general: {
          photo: speakerKovriginImage,
        },
        translation: {
          ru: {
            name: 'Алексей Ковригин',
            description: 'Директор по продукту hotellab:RMS',
          },
          en: {
            name: 'Алексей Ковригин',
            description: 'Директор по продукту hotellab:RMS',
          },
        },
      },
      {
        general: {
          photo: speakerKireevImage,
        },
        translation: {
          ru: {
            name: 'Федор Киреев',
            description: 'Директор по продукту, Hotbot.ai',
          },
          en: {
            name: 'Федор Киреев',
            description: 'Директор по продукту, Hotbot.ai',
          },
        },
      },
      {
        general: {
          photo: speakerBezbImage,
        },
        translation: {
          ru: {
            name: 'Ксения Безбородова',
            description: 'Руководитель команды по развитию бизнеса в СЗФО и Республике Беларусь, Оstrovok',
          },
          en: {
            name: 'Ксения Безбородова',
            description: 'Руководитель команды по развитию бизнеса в СЗФО и Республике Беларусь, Оstrovok',
          },
        },
      },
      {
        general: {
          photo: speakerSharovImage,
        },
        translation: {
          ru: {
            name: 'Александр Шаров',
            description: 'Директор департамента по работе с ключевыми клиентами TravelLine',
          },
          en: {
            name: 'Александр Шаров',
            description: 'Директор департамента по работе с ключевыми клиентами TravelLine',
          },
        },
      },
      {
        general: {
          photo: speakerIpatovaImage,
        },
        translation: {
          ru: {
            name: 'Мария Ипатова',
            description: 'Business development manager, "Авиасейлс"',
          },
          en: {
            name: 'Мария Ипатова',
            description: 'Business development manager, "Авиасейлс"',
          },
        },
      },
      {
        general: {
          photo: speakerBihalovaImage,
        },
        translation: {
          ru: {
            name: 'Елена Быхалова',
            description: 'Эксперт по управлению доходами',
          },
          en: {
            name: 'Елена Быхалова',
            description: 'Эксперт по управлению доходами',
          },
        },
      },
      {
        general: {
          photo: speakerCvangImage,
        },
        translation: {
          ru: {
            name: 'Лолита Цванг',
            description: 'Региональный директор по управлению доходами Cosmos Hotel Group',
          },
          en: {
            name: 'Лолита Цванг',
            description: 'Региональный директор по управлению доходами Cosmos Hotel Group',
          },
        },
      },
      {
        general: {
          photo: speakerZlatcusImage,
        },
        translation: {
          ru: {
            name: 'Ольга Златкус',
            description: 'Менеджер по развитию бизнеса Hotel Advisors',
          },
          en: {
            name: 'Ольга Златкус',
            description: 'Менеджер по развитию бизнеса Hotel Advisors',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
@media (max-width: $breakpoint-mobile) {
  .marketing-section-analytics-info-description {
    text-align: left;
  }
}

.page-section-wrapper.pt-0 {
  padding-top: 0;
}

.page-section-reg-wrapper {
  padding: 30px;
}

.page-section-reg-wrapper span {
  margin-right: 20px;
}

.demo-main-photo-title-date {
  white-space: pre-wrap;
}

.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;

  .page-section-reg-wrapper {
    padding: 30px;
  }

  .page-section-reg-wrapper span {
    margin-right: 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;
    background-color: #5d5ca8;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 700px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
</style>
