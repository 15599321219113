<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        {{ getTranslateFromItem(main.translation, 'title') }}
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
      <div class="page-section-reg-wrapper">
        <a @click.prevent="scrollToRegSection">
          <BaseButton filled>Зарегистрироваться</BaseButton>
        </a>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">{{ getTranslateFromItem(e.translation, 'title') }}</div>
          <div class="programme-item-content">
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description" v-html="getTranslateFromItem(e.translation, 'description')"></div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarSpeakersTitle') }}</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div id="regSection" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarFormTitle') }} на воркшоп в Санкт-Петербурге <br/>(Отель Введенский, 10:00-14:00)</div>
        <div id="hubspotForm" ref="hubspot-form1"></div>
      </div>
    </div>
    <div class="page-section-wrapper pt-0">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarFormTitle') }} на воркшоп в Москве <br/>(Отель СтандАрт, 10:00-14:00)</div>
        <div id="hubspotForm2" ref="hubspot-form2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import webinarImage from '@/assets/images/webinar/webinar2.png';
import seoImage from '@/assets/images/webinar/feb22.png';
import speakerNikImage from '@/assets/images/speakers/nikos.png';
import speakerMoskvinaImage from '@/assets/images/speakers/moskvina.jpg';
import speakerAnatoliyImage from '@/assets/images/speakers/anatoliy.jpg';
import speakerKireevImage from '@/assets/images/speakers/kireev.jpg';
import speakerErmakovaImage from '@/assets/images/speakers/ermakova.png';
import speakerSokolovaImage from '@/assets/images/speakers/sokolova.png';
import travellineImage from '@/assets/images/partners/travelline.png';
import hotbotImage from '@/assets/images/partners/HotbotBlack.png';
import hrsImage from '@/assets/images/partners/hrs.jpg';
import otaImage from '@/assets/images/partners/ota.png';
import sovrImage from '@/assets/images/partners/sovr.png';
import wtImage from '@/assets/images/partners/wt.png';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
      this.appendForm2();
    },
  },
  mounted() {
    this.appendForm();
    this.appendForm2();
  },
  methods: {
    scrollToRegSection() {
      const section = document.getElementById("regSection");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/shell.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "938335f3-4dd2-4433-9c06-e452409a3df6",
            target: '#hubspotForm',
          });
        }
      })
    },
    appendForm2() {
      this.$refs["hubspot-form2"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/shell.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "9fc3d922-a397-47ce-a4bc-4b29349cf63e",
            target: '#hubspotForm2',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: 'Hot Revenue Workshop',
          date: 'Максимизация дохода на всем пути гостя \n (Customer Journey Map) \n 1 Марта - Санкт-Петербург | 10:00-14:00 \n 3 Марта - Москва | 10:00-14:00'
        },
        en: {
          title: 'Hot Revenue Workshop',
          date: 'Максимизация дохода на всем пути гостя \n (Customer Journey Map) \n 1 Марта - Санкт-Петербург | 10:00-14:00 \n 3 Марта - Москва | 10:00-14:00'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'О чём  наш воркшоп',
          before: '<p>Компания hotellab, совместно с партнерами  OTA Insight, Hotbot и TravelLine, подготовили Hot Revenue Workshop.  Воркшоп пройдет оффлайн, в Санкт-Петербурге (Отель Введенский) и в Москве (Отель СтандАрт).</p>' +
          '<p>Тема: как revenue management и e commerce решения помогают максимизировать выручку в каждой точке присутствия клиента.</p>' +
          '<p>На воркшопе мы:</p>' +
          '<ul><li>выясним, какие технологии Revenue Management применять для максимизации дохода на каждом этапе взаимодействия с гостем</li>' +
          '<li>рассмотрим, как анализировать спрос, автоматизировать цены, как работать над тарифами с помощью понимания спроса, рынка и конкурентов</li>' +
          '<li>разберем, как грамотно начать продавать с этапа бронирования, и продолжить во время всего проживания клиента в отеле</li>' +
          '<li>изучим, как эффективно представлять свой отель онлайн, как работать с лояльностью гостя</li>' +
          '<li>в интерактивном формате вовлечем всю аудиторию</li></ul>' +
          '<p>Наш воркшоп поможет отельерам и собственникам получать максимально возможный доход от каждого шага клиента.</p>' +
          '<p>Во время воркшопа будет отличная возможность задать вопросы:</p>' +
          '<ul><li>экспертам по Revenue Management</li>' +
          '<li>разработчикам современных технологических решений в сфере Revenue Management и онлайн продаж</li></ul>' +
          '<p>Воркшоп интерактивный: участники смогут в режиме онлайн делиться своим мнением, будут участвовать в опросах и подробно изучат актуальные материалы.</p>' +
          '<p>Мероприятие бесплатное.<br/>Приглашаем сотрудников коммерческих служб, генеральных менеджеров и владельцев отелей</p>' +
          '<p>Для участия в воркшопе необходимо зарегистрироваться.</p>',
          after: '',
        },
        en: {
          title: 'What is our workshop about',
          before: '<p>Компания hotellab, совместно с партнерами  OTA Insight, Hotbot и TravelLine, подготовили Hot Revenue Workshop.  Воркшоп пройдет оффлайн, в Санкт-Петербурге в Санкт-Петербурге (Отель Введенский) и в Москве (Отель СтандАрт).</p>' +
          '<p>Тема: как revenue management и e commerce решения помогают максимизировать выручку в каждой точке присутствия клиента.</p>' +
          '<p>На воркшопе мы:</p>' +
          '<ul><li>выясним, какие технологии Revenue Management применять для максимизации дохода на каждом этапе взаимодействия с гостем</li>' +
          '<li>рассмотрим, как анализировать спрос, автоматизировать цены, как работать над тарифами с помощью понимания спроса, рынка и конкурентов</li>' +
          '<li>разберем, как грамотно начать продавать с этапа бронирования, и продолжить во время всего проживания клиента в отеле</li>' +
          '<li>изучим, как эффективно представлять свой отель онлайн, как работать с лояльностью гостя</li>' +
          '<li>в интерактивном формате вовлечем всю аудиторию</li></ul>' +
          '<p>Наш воркшоп поможет отельерам и собственникам получать максимально возможный доход от каждого шага клиента.</p>' +
          '<p>Во время воркшопа будет отличная возможность задать вопросы:</p>' +
          '<ul><li>экспертам по Revenue Management</li>' +
          '<li>разработчикам современных технологических решений в сфере Revenue Management и онлайн продаж</li></ul>' +
          '<p>Воркшоп интерактивный: участники смогут в режиме онлайн делиться своим мнением, будут участвовать в опросах и подробно изучат актуальные материалы.</p>' +
          '<p>Мероприятие бесплатное.<br/>Приглашаем сотрудников коммерческих служб, генеральных менеджеров и владельцев отелей</p>' +
          '<p>Для участия в воркшопе необходимо зарегистрироваться.</p>',
          after: '',
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 0,
                speaker: false,
              },
              translation: {
                ru: {
                  title: '10:00 - 10:30',
                  speaker: '',
                  modules: 'Приветственный кофе и приветственные слова партнеров',
                },
                en: {
                  title: '10:00 - 10:30',
                  speaker: '',
                  modules: 'Приветственный кофе и приветственные слова партнеров',
                },
              },
            },
            {
              general: {
                id: 1,
                speaker: false,
              },
              translation: {
                ru: {
                  title: '10:30 - 12:00',
                  speaker: '',
                  modules: 'Настройка дистрибуции. Как качественно представить себя онлайн. TravelLine \n' +
                  'Стимуляция спроса с помощью рекламы. Анализ источников спроса. Сквозная аналитика от hotellab \n' +
                  'Увеличение воронки продаж с помощью понимания спроса. Market Insight от OTA Inisght \n' +
                  'Основы онлайн-продажи дополнительных услуг до заезда гостя. Hotbot.ai \n',
                },
                en: {
                  title: '10:30 - 12:00',
                  speaker: '',
                  modules: 'Настройка дистрибуции. Как качественно представить себя онлайн. TravelLine \n' +
                  'Стимуляция спроса с помощью рекламы. Анализ источников спроса. Сквозная аналитика от hotellab \n' +
                  'Увеличение воронки продаж с помощью понимания спроса. Market Insight от OTA Inisght \n' +
                  'Основы онлайн-продажи дополнительных услуг до заезда гостя. Hotbot.ai \n',
                },
              },
            },
            {
              general: {
                id: 2,
                speaker: false,
              },
              translation: {
                ru: {
                  title: '12:00 - 12:30',
                  speaker: '',
                  modules: 'Кофе брейк & нетворкинг \n'
                },
                en: {
                  title: '12:00 - 12:30',
                  speaker: '',
                  modules: 'Кофе брейк & нетворкинг \n'
                },
              },
            },
            {
              general: {
                speaker: false,
                id: 3,
              },
              translation: {
                ru: {
                  title: '12:30 - 14:00',
                  speaker: '',
                  modules: 'Принятие решений по управлению спросом на основе отчетов. Автоматизация цен. RMS от hotellab \n' +
                  'Формирование эффективных тарифных политик за счет понимания  рынка и конкурентов. Rate Insight от OTA Insight \n' +
                  'Основы онлайн-продажи дополнительных услуг во время проживания гостя. Hotbot.ai \n' +
                  'Работа с лояльностью гостя после выезда. TravelLine CRM/Лояльность \n'
                },
                en: {
                  title: '12:30 - 14:00',
                  speaker: '',
                  modules: 'Принятие решений по управлению спросом на основе отчетов. Автоматизация цен. RMS от hotellab \n' +
                  'Формирование эффективных тарифных политик за счет понимания  рынка и конкурентов. Rate Insight от OTA Insight \n' +
                  'Основы онлайн-продажи дополнительных услуг во время проживания гостя. Hotbot.ai \n' +
                  'Работа с лояльностью гостя после выезда. TravelLine CRM/Лояльность \n'
                },
              },
            },
          ],
          translation: {
            ru: {
              title: '',
            },
            en: {
              title: '',
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Программа воркшопа',
          description: '',
        },
        en: {
          title: 'Программа воркшопа',
          description: '',
        },
      },
    },
    partners: [
      {
        "id": 1,
        "generic": {
          "photo": wtImage,
          "url": "https://welcometimes.ru/",
        },
      },
      {
        "id": 2,
        "generic": {
          "photo": travellineImage,
          "url": "https://www.travelline.ru/",
        },
      },
      {
        "id": 3,
        "generic": {
          "photo": "https://hotellab.io/media/hotelier.png",
          "url": "https://hotelier.pro",
        },
      },
      {
        "id": 5,
        "generic": {
          "photo": hotbotImage,
          "url": "https://hotbot.ai/eng",
        },
      },
      {
        "id": 6,
        "generic": {
          "photo": otaImage,
          "url": "https://www.otainsight.com/",
        },
      },
      {
        "id": 7,
        "generic": {
          "photo": sovrImage,
          "url": "https://hotel.report/",
        },
      },
      {
        "id": 8,
        "generic": {
          "photo": hrsImage,
          "url": "https://www.hrsinternational.com/russia/ru/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho.webp',
        },
        translation: {
          ru: {
            title: 'Для кого?',
            description: 'Для сотрудников коммерческих служб, генеральных менеджеров и собственников отелей  классом 3 звезды и выше, объемом более 50 номеров.',
          },
          en: {
            title: 'For Who?',
            description: 'Для сотрудников коммерческих служб, генеральных менеджеров и собственников отелей  классом 3 звезды и выше, объемом более 50 номеров.',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy.jpg',
        },
        translation: {
          ru: {
            title: 'Что даст?',
            description: '<ul><li>Инструменты увеличения выручки в каждой точке присутствия гостя.</li>' +
            '<li>Возможность быть в тренде и опережать конкурентов.</li>' +
            '<li>Аналитику спроса.</li></ul>'
          },
          en: {
            title: 'What Can You Get?',
            description: '<ul><li>Инструменты увеличения выручки в каждой точке присутствия гостя.</li>' +
            '<li>Возможность быть в тренде и опережать конкурентов.</li>' +
            '<li>Аналитику спроса.</li></ul>'
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerAnatoliyImage,
        },
        translation: {
          ru: {
            name: 'Анатолий Бурдаков',
            description: 'Основатель hotellab.io',
          },
          en: {
            name: 'Anatoly Burdakov',
            description: 'Founder of hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerMoskvinaImage,
        },
        translation: {
          ru: {
            name: 'Мария Москвина',
            description: 'Коммерческий директор Hotellab.io',
          },
          en: {
            name: 'Maria Moskvina',
            description: 'Коммерческий директор Hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerNikImage,
        },
        translation: {
          ru: {
            name: 'Nikolaos Tsitsoulis',
            description: 'Старший Менеджер по Развитию Бизнеса OTA Insight',
          },
          en: {
            name: 'Nikolaos Tsitsoulis',
            description: 'Старший Менеджер по Развитию Бизнеса OTA Insight',
          },
        },
      },
      {
        general: {
          photo: speakerKireevImage,
        },
        translation: {
          ru: {
            name: 'Федор Киреев',
            description: 'CEO hotbot.ai',
          },
          en: {
            name: 'Федор Киреев',
            description: 'CEO hotbot.ai',
          },
        },
      },
      {
        general: {
          photo: speakerErmakovaImage,
        },
        translation: {
          ru: {
            name: 'Елена Ермакова',
            description: 'региональный представитель TravelLine в Москве',
          },
          en: {
            name: 'Елена Ермакова',
            description: 'региональный представитель TravelLine в Москве',
          },
        },
      },
      {
        general: {
          photo: speakerSokolovaImage,
        },
        translation: {
          ru: {
            name: 'Дарья Соколова',
            description: 'региональный представитель TravelLine в Санкт-Петербурге',
          },
          en: {
            name: 'Дарья Соколова',
            description: 'региональный представитель TravelLine в Санкт-Петербурге',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.page-section-wrapper.pt-0 {
  padding-top: 0;
}

.page-section-reg-wrapper {
  padding: 30px;
}

.page-section-reg-wrapper span {
  margin-right: 20px;
}

.demo-main-photo-title-date {
  white-space: pre-wrap;
}
.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;


  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;
    background-color: #5d5ca8;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 700px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
</style>
