<template>
  <div id="about-us">
    <PageSection
      v-if="getTranslate('aboutUsEstoniaTitle') !== '{{hidden}}'"
      :max-width="1050"
    >
      <ImageWithInfo
        :image-url="require('@/assets/images/about-us/estonia.svg')"
        :description="getTranslate('aboutUsEstoniaText')"
        :title="getTranslate('aboutUsEstoniaTitle')"
        large-image
      />
    </PageSection>
    <div class="page-section-wrapper" id="about-us-section-creator-wrapper">
      <div class="page-section-inner" id="about-us-section-creator">
        <div id="about-us-section-creator-image">
          <div
            id="about-us-section-creator-image-inner"
            :style="{
              background: `url('https://hotellab.io/media/estonianBasedPhoto-min.png') 50% 50%`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }"
          />
        </div>
        <div id="about-us-section-creator-info">
          <div id="about-us-section-creator-info-title">
            {{ getTranslate('aboutUsCreatorTitle') }}
          </div>
          <div id="about-us-section-creator-info-description">
            {{ getTranslate('aboutUsCreatorOutTeam') }}
            <br/>
            <br/>
            {{ getTranslate('aboutUsCreatorTrend') }}
            <ul>
              <li>{{ getTranslate('aboutUsCreatorTrends1') }}</li>
              <li>{{ getTranslate('aboutUsCreatorTrends2') }}</li>
              <li>{{ getTranslate('aboutUsCreatorTrends3') }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper" id="about-us-section-team-wrapper">
      <div class="page-section-inner" id="about-us-section-team">
        <div id="about-us-section-team-title" class="page-section-title-center">
          {{ getTranslate('aboutUsOurTeamTitle') }}
        </div>
        <div id="about-us-section-team-persons">
          <div class="about-us-section-team-persons-item" v-for="e in team" v-if="e['translation'][lang]">
            <div class="about-us-section-team-persons-item-photo">
              <div
                class="about-us-section-team-persons-item-photo-inner"
                :style="{
                  background: `url('${e.generic.photo}') 50% 50%`,
                  backgroundSize: 'cover',
                }"
              />
            </div>
            <div
              class="about-us-section-team-persons-item-name"
              v-text="`${e['translation'][lang].first_name} ${e['translation'][lang].last_name}`"
            />
            <div
              class="about-us-section-team-persons-item-position"
              v-text="e['translation'][lang].position"
            />
            <div
              v-if="e.generic.linkedin_url"
              class="about-us-section-team-persons-item-links"
            >
              <a :href="e.generic.linkedin_url" target="_blank">
                <BaseIcon icon="linkedIn" :size="32"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PageSection from "@/components/PageSection";
import ImageWithInfo from "@/components/widgets/ImageWithInfo";
import BaseIcon from "@/components/BaseIcon";

export default {
  name: "AboutUs",
  computed: {
    ...mapGetters([
      'getTranslate',
      'lang',
      'carousels',
      'trust',
      'team',
      'images',
      'integrations',
    ]),
  },
  components: {
    PageSection,
    ImageWithInfo,
    BaseIcon
  },
}
</script>

<style lang="scss" scoped>

#about-us-section-creator-wrapper {
  background-color: $primary;
  color: white;

  #about-us-section-creator {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      padding: 20px 10px;
      background-color: rgba(0, 0, 0, 0.03);
      border-radius: 20px;
    }

    &-image {
      width: 45%;
      align-self: stretch;
      border-radius: 20px;
      overflow: hidden;
      box-sizing: border-box;
      max-width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: calc((9/20) * 100%);
      position: relative;

      @media (max-width: $breakpoint-desktop) {

        @media (max-width: $breakpoint-tablet) {

          @media (max-width: $breakpoint-tablet) {
            align-self: center;
            max-width: 100%;
            width: 100%;
            padding-top: 70%;  

            @media (max-width: $breakpoint-mobile) {
              margin-bottom: 20px;
              padding-top: 100%;  
            }
          }
        }
      }

      &-inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &-info {
      flex-grow: 1;
      width: 50%;
      padding-left: 100px;
      transition: .3s;
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 20px;

      @media (max-width: $breakpoint-desktop) {
        padding-left: 60px;

        @media (max-width: $breakpoint-tablet) {
          padding-left: 40px;

          @media (max-width: $breakpoint-tablet) {
            padding: 20px;
            width: 100%;

            @media (max-width: $breakpoint-mobile) {
              padding: 0;
            }
          }
        }
      }

      &-title {
        font-weight: 900;
        font-size: 38px;
        margin-bottom: 20px;
      }

      &-description {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        box-sizing: border-box;
        font-weight: 400;

        @media (max-width: $breakpoint-desktop) {
          font-size: 16px;
          line-height: 24px;
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        ul {
          margin: 0;
          padding-left: 0;
          list-style-type: none;

          li {
            margin: 15px 0;
            background-color: rgba(255, 255, 255, 0.11);
            border-radius: 10px;
            padding: 20px;
            box-sizing: border-box;

            @media (max-width: $breakpoint-mobile) {
              padding: 15px;
              margin: 10px 0;
            }

          }
        }
      }
    }
  }
}

#about-us-section-team-wrapper {
  #about-us-section-team {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-persons {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: center;

      .about-us-section-team-persons-item {
        width: 25%;
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        &-photo {
          width: 100%;
          border-radius: 1000px;
          padding-top: 100%;
          overflow: hidden;
          position: relative;

          &-inner {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 3);

          @media (max-width: $breakpoint-mobile) {
            width: calc(100% / 2);
            padding: 10px;
          }
        }

        &-name {
          width: 100%;
          font-weight: 500;
          margin: 10px 0 4px;
          font-size: 20px;
        }

        &-position {
          font-size: 14px;
        }

        &-links {
          margin-top: 12px;
        }
      }
    }
  }
}


</style>
