var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"about-us"}},[(_vm.getTranslate('aboutUsEstoniaTitle') !== '{{hidden}}')?_c('PageSection',{attrs:{"max-width":1050}},[_c('ImageWithInfo',{attrs:{"image-url":require('@/assets/images/about-us/estonia.svg'),"description":_vm.getTranslate('aboutUsEstoniaText'),"title":_vm.getTranslate('aboutUsEstoniaTitle'),"large-image":""}})],1):_vm._e(),_c('div',{staticClass:"page-section-wrapper",attrs:{"id":"about-us-section-creator-wrapper"}},[_c('div',{staticClass:"page-section-inner",attrs:{"id":"about-us-section-creator"}},[_c('div',{attrs:{"id":"about-us-section-creator-image"}},[_c('div',{style:({
            background: `url('https://hotellab.io/media/estonianBasedPhoto-min.png') 50% 50%`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }),attrs:{"id":"about-us-section-creator-image-inner"}})]),_c('div',{attrs:{"id":"about-us-section-creator-info"}},[_c('div',{attrs:{"id":"about-us-section-creator-info-title"}},[_vm._v(" "+_vm._s(_vm.getTranslate('aboutUsCreatorTitle'))+" ")]),_c('div',{attrs:{"id":"about-us-section-creator-info-description"}},[_vm._v(" "+_vm._s(_vm.getTranslate('aboutUsCreatorOutTeam'))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.getTranslate('aboutUsCreatorTrend'))+" "),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.getTranslate('aboutUsCreatorTrends1')))]),_c('li',[_vm._v(_vm._s(_vm.getTranslate('aboutUsCreatorTrends2')))]),_c('li',[_vm._v(_vm._s(_vm.getTranslate('aboutUsCreatorTrends3')))])])])])])]),_c('div',{staticClass:"page-section-wrapper",attrs:{"id":"about-us-section-team-wrapper"}},[_c('div',{staticClass:"page-section-inner",attrs:{"id":"about-us-section-team"}},[_c('div',{staticClass:"page-section-title-center",attrs:{"id":"about-us-section-team-title"}},[_vm._v(" "+_vm._s(_vm.getTranslate('aboutUsOurTeamTitle'))+" ")]),_c('div',{attrs:{"id":"about-us-section-team-persons"}},_vm._l((_vm.team),function(e){return (e['translation'][_vm.lang])?_c('div',{staticClass:"about-us-section-team-persons-item"},[_c('div',{staticClass:"about-us-section-team-persons-item-photo"},[_c('div',{staticClass:"about-us-section-team-persons-item-photo-inner",style:({
                background: `url('${e.generic.photo}') 50% 50%`,
                backgroundSize: 'cover',
              })})]),_c('div',{staticClass:"about-us-section-team-persons-item-name",domProps:{"textContent":_vm._s(`${e['translation'][_vm.lang].first_name} ${e['translation'][_vm.lang].last_name}`)}}),_c('div',{staticClass:"about-us-section-team-persons-item-position",domProps:{"textContent":_vm._s(e['translation'][_vm.lang].position)}}),(e.generic.linkedin_url)?_c('div',{staticClass:"about-us-section-team-persons-item-links"},[_c('a',{attrs:{"href":e.generic.linkedin_url,"target":"_blank"}},[_c('BaseIcon',{attrs:{"icon":"linkedIn","size":32}})],1)]):_vm._e()]):_vm._e()}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }