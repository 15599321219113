<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        {{ getTranslateFromItem(main.translation, 'title') }}
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
      <div class="page-section-reg-wrapper">
        <a @click.prevent="scrollToRegSection">
          <BaseButton filled>Зарегистрироваться</BaseButton>
        </a>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">{{ getTranslateFromItem(e.translation, 'title') }}</div>
          <div class="programme-item-content">
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description" v-html="getTranslateFromItem(e.translation, 'description')"></div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarSpeakersTitle') }}</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div id="regSection" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarFormTitle') }}</div>
        <div id="hubspotForm" ref="hubspot-form1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import webinarImage from '@/assets/images/webinar/webinar.jpg';
import seoImage from '@/assets/images/webinar/feb22.png';
import speakerMoskvinaImage from '@/assets/images/speakers/moskvina.jpg';
import speakerAnatoliyImage from '@/assets/images/speakers/anatoliy.jpg';
import speakerEshmekovImage from '@/assets/images/speakers/eshmekov.jpg';
import speakerLapikoImage from '@/assets/images/speakers/lapiko.jpg';
import speakerChapliginaImage from '@/assets/images/speakers/chapligina.png';
import travellineImage from '@/assets/images/partners/travelline.png';
import hrImage from '@/assets/images/partners/hotelreport.png';
import hrsImage from '@/assets/images/partners/hrs.jpg';
import ostrovokImage from '@/assets/images/partners/ostrovok.png';
import rhaImage from '@/assets/images/partners/rha.png';
import ghcImage from '@/assets/images/partners/ghc.png';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
  },
  methods: {
    scrollToRegSection() {
      const section = document.getElementById("regSection");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "ac74c877-a5d4-400b-a450-0f339c91a12b",
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: 'Revenue нетворкинг',
          date: 'Для отелей номерным фондом 50 номеров и более \n Москва | 22 сентября | 15:30 - 19:00 \n Отель Mövenpick Moscow Taganskaya'
        },
        en: {
          title: 'Revenue нетворкинг',
          date: 'Для отелей номерным фондом 50 номеров и более \n Москва | 22 сентября | 15:30 - 19:00 \n Отель Mövenpick Moscow Taganskaya'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'О чем наша встреча',
          before: '<p>В текущих реалиях ограниченного или полного отсутствия международных брендов, гостиничная индустрия столкнулась с множеством вызовов.</br>Один из ключевых вызовов —  сохранение культуры управления отелями на основе максимально высоких стандартов. И крайне важный стандарт, заданный международными сетями, это профессиональное, современное видение управления доходами.</p>' +
          '<p>22 Сентября, компания hotellab, совместно с партнёрами HRS, Travelline и Emering Travel Group (Ostrovok),  проведет, в формате нетворкинга, мероприятие для генеральных менеджеров, коммерческих директоров и revenue менеджеров отелей с номерным фондом 50 номеров и более.</p>' +
          '<p>Команда партнеров собирает отельеров для непринужденного разговора о сложившейся ситуации в индустрии. Будем обсуждать актуальные тренды в развитии отелей, правила и стандарты управления доходами и ценообразованием, новые возможности во взаимодействии с ОТА каналами, пути дальнейшего профессионального совершенствования в сфере revenue management.</p>' +
          '<p>На мероприятии отельеров ожидает:</p>' +
          '<ul><li>много встреч с коллегами по цеху</li>' +
          '<li>презентации аналитики и ключевых трендов в индустрии</li>' +
          '<li>интерактивное построение совместных планов развития</li>' +
          '<li>живое непринужденное общение</li>' +
          '<li>практические и полезные материалы</li>' +
          '<li>возможности непосредственно задать вопросы и получить ответы от ведущих компаний в гостиничной digital-индустрии</li>',
          after: 'Наш нетворкинг должен помочь менеджменту отелей эффективнее развивать свой бизнес, двигаться вперед, не оглядываясь на проблемы, следовать лучшим современным прогрессивным стандартам и трендам в индустрии.'
        },
        en: {
          title: 'О чем наша встреча',
          before: '<p>В текущих реалиях ограниченного или полного отсутствия международных брендов, гостиничная индустрия столкнулась с множеством вызовов.</br>Один из ключевых вызовов —  сохранение культуры управления отелями на основе максимально высоких стандартов. И крайне важный стандарт, заданный международными сетями, это профессиональное, современное видение управления доходами.</p>' +
          '<p>22 Сентября, компания hotellab, совместно с партнёрами HRS, Travelline и Emering Travel Group (Ostrovok),  проведет, в формате нетворкинга, мероприятие для генеральных менеджеров, коммерческих директоров и revenue менеджеров отелей с номерным фондом 50 номеров и более.</p>' +
          '<p>Команда партнеров собирает отельеров для непринужденного разговора о сложившейся ситуации в индустрии. Будем обсуждать актуальные тренды в развитии отелей, правила и стандарты управления доходами и ценообразованием, новые возможности во взаимодействии с ОТА каналами, пути дальнейшего профессионального совершенствования в сфере revenue management.</p>' +
          '<p>На мероприятии отельеров ожидает:</p>' +
          '<ul><li>много встреч с коллегами по цеху</li>' +
          '<li>презентации аналитики и ключевых трендов в индустрии</li>' +
          '<li>интерактивное построение совместных планов развития</li>' +
          '<li>живое непринужденное общение</li>' +
          '<li>практические и полезные материалы</li>' +
          '<li>возможности непосредственно задать вопросы и получить ответы от ведущих компаний в гостиничной digital-индустрии</li>',
          after: 'Наш нетворкинг должен помочь менеджменту отелей эффективнее развивать свой бизнес, двигаться вперед, не оглядываясь на проблемы, следовать лучшим современным прогрессивным стандартам и трендам в индустрии.'
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 0,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '15.30-16.00 Нетворкинг',
                  speaker: '',
                  modules: '- Приветственный кофе и общение с коллегами \n'
                },
                en: {
                  title: '15.30-16.00 Нетворкинг',
                  speaker: '',
                  modules: '- Приветственный кофе и общение с коллегами \n',
                },
              },
            },
            {
              general: {
                id: 1,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '16.00 -17.00 Деловая программа: Управление отелем',
                  speaker: '',
                  modules: '- Новые инструменты и технологии для управления отелем (HRS) \n- Аналитика от hotellab и фокус на устойчивое развитие (умное ценообразование, управление прибылью и сквозная аналитика)'
                },
                en: {
                  title: '16.00 -17.00 Деловая программа: Управление отелем',
                  speaker: '',
                  modules: '- Новые инструменты и технологии для управления отелем (HRS) \n- Аналитика от hotellab и фокус на устойчивое развитие (умное ценообразование, управление прибылью и сквозная аналитика)'
                },
              },
            },
            {
              general: {
                id: 2,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '17.00 -18.00 Деловая программа: E-commerce',
                  speaker: '',
                  modules: '- "Прямые продажи. А что, так можно было (TravelLine)" \n- "Эффективный ревенью-менеджмент: инструменты и рекомендации Ostrovok.ru"'
                },
                en: {
                  title: '17.00 -18.00 Деловая программа: E-commerce',
                  speaker: '',
                  modules: '- "Прямые продажи. А что, так можно было (TravelLine)" \n- "Эффективный ревенью-менеджмент: инструменты и рекомендации Ostrovok.ru"'
                },
              },
            },
            {
              general: {
                id: 3,
                speaker: true,
              },
              translation: {
                ru: {
                  title: '18:00 - 19:00 Нетворкинг',
                  speaker: '',
                  modules: '- Коктейль от компании HRS'
                },
                en: {
                  title: '18:00 - 19:00 Нетворкинг',
                  speaker: '',
                  modules: '- Коктейль от компании HRS'
                },
              },
            },
          ],
          translation: {
            ru: {
              title: '',
            },
            en: {
              title: '',
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Программа',
          description: '',
        },
        en: {
          title: 'Program',
          description: '',
        },
      },
    },
    partners: [
      {
        "id": 0,
        "generic": {
          "photo": travellineImage,
          "url": "https://www.travelline.ru/",
        },
      },
      {
        "id": 1,
        "generic": {
          "photo": hrImage,
          "url": "https://hotel.report/",
        },
      },
      {
        "id": 2,
        "generic": {
          "photo": "https://hotellab.io/media/hotelier.png",
          "url": "https://hotelier.pro",
        },
      },
      {
        "id": 3,
        "generic": {
          "photo": ostrovokImage,
          "url": "https://ostrovok.ru/",
        },
      },
      {
        "id": 8,
        "generic": {
          "photo": hrsImage,
          "url": "https://www.hrsinternational.com/russia/ru/",
        },
      },
      {
        "id": 9,
        "generic": {
          "photo": rhaImage,
          "url": "https://www.hospitalityawards.ru/",
        },
      },
      {
        "id": 10,
        "generic": {
          "photo": ghcImage,
          "url": "https://globalhospitalityclub.ru/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho.webp',
        },
        translation: {
          ru: {
            title: 'Для кого?',
            description: 'Для генеральных менеджеров, коммерческих директоров и revenue менеджеров отелей номерным фондом 50 номеров и более. \n' +
            'Для тех управленцев, которые стремятся: \n' +
            '- всегда оставаться профессионалами \n' +
            '- не отходить от современных стандартов в управлении \n' +
            '- следовать актуальным трендам в развитии гостиничного бизнеса.',
          },
          en: {
            title: 'For Who?',
            description: 'Для генеральных менеджеров, коммерческих директоров и revenue менеджеров отелей номерным фондом 50 номеров и более. \n' +
            'Для тех управленцев, которые стремятся: \n' +
            '- всегда оставаться профессионалами \n' +
            '- не отходить от современных стандартов в управлении \n' +
            '- следовать актуальным трендам в развитии гостиничного бизнеса.',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy.jpg',
        },
        translation: {
          ru: {
            title: 'Что даст?',
            description: '<p>У участников нетворкинга будут возможности:</p>' +
            '<ul><li>Узнать передовые тренды и важные изменения в сферах управления доходами и онлайн дистрибуции</li>' +
            '<li>Установить новые и укрепить старые связи среди коллег и партнеров</li>' +
            '<li>Получить конкурентные преимущества для устойчивого и финансово прибыльного развития</li></ul>'
          },
          en: {
            title: 'What Can You Get?',
            description: '<p>У участников нетворкинга будут возможности:</p>' +
            '<ul><li>Узнать передовые тренды и важные изменения в сферах управления доходами и онлайн дистрибуции</li>' +
            '<li>Установить новые и укрепить старые связи среди коллег и партнеров</li>' +
            '<li>Получить конкурентные преимущества для устойчивого и финансово прибыльного развития</li></ul>'
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerAnatoliyImage,
        },
        translation: {
          ru: {
            name: 'Анатолий Бурдаков',
            description: 'Основатель hotellab.io',
          },
          en: {
            name: 'Anatoly Burdakov',
            description: 'Founder of hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerMoskvinaImage,
        },
        translation: {
          ru: {
            name: 'Мария Москвина',
            description: 'Коммерческий директор Hotellab.io',
          },
          en: {
            name: 'Maria Moskvina',
            description: 'Коммерческий директор Hotellab.io',
          },
        },
      },
      {
        general: {
          photo: speakerChapliginaImage,
        },
        translation: {
          ru: {
            name: 'Анна Чаплыгина',
            description: 'Руководитель российского направления по развитию бизнеса Ostrovok.ru',
          },
          en: {
            name: 'Анна Чаплыгина',
            description: 'Руководитель российского направления по развитию бизнеса Ostrovok.ru',
          },
        },
      },
      {
        general: {
          photo: speakerLapikoImage,
        },
        translation: {
          ru: {
            name: 'Денис Лапыко',
            description: 'Исполнительный директор HRS',
          },
          en: {
            name: 'Денис Лапыко',
            description: 'Исполнительный директор HRS',
          },
        },
      },
      {
        general: {
          photo: speakerEshmekovImage,
        },
        translation: {
          ru: {
            name: 'Александр Ешмеков',
            description: 'Директор по работе с отелями в TravelLine',
          },
          en: {
            name: 'Александр Ешмеков',
            description: 'Директор по работе с отелями в TravelLine',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
@media (max-width: $breakpoint-mobile) {
  .marketing-section-analytics-info-description {
    text-align: left;
  }
}

.page-section-wrapper.pt-0 {
  padding-top: 0;
}

.page-section-reg-wrapper {
  padding: 30px;
}

.page-section-reg-wrapper span {
  margin-right: 20px;
}

.demo-main-photo-title-date {
  white-space: pre-wrap;
}

.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;

  .page-section-reg-wrapper {
    padding: 30px;
  }

  .page-section-reg-wrapper span {
    margin-right: 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;
    background-color: #5d5ca8;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 700px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 500px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
</style>
